.about-container {
    height: 80%;
    width: 100%;
    box-sizing: border-box;
    padding-top: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
}

.about-container div {
    margin: 0px 10px 0px;
}

.about-content-container {
    margin-top: 50px;
    height: calc(76% - 100px);
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    margin-bottom: 50px;
    margin-right: 7%;
}

.about-content-h1 {
    font-size: 30px;
    font-family: 'Open Sans', 'Montserrat', sans-serif;
    font-weight: bold;
    margin-bottom: 20px;
}

.about-content {
    width: 95%;
    margin-bottom: 50px;
    font-size: 20px;
    line-height: 25px;
}

.about-img-container {
    margin-top: 100px;
    height: 475px;
    width: 350px;
    border-radius: 5px;
    overflow: hidden;
}

.about-img {
    height: 100%;
    width: 100%;
}