.skills-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /* margin-top: 8%; */
}

.skills-list {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    align-items: center;
    justify-content: space-evenly;
    width: 60%;
    box-sizing: border-box
}

.skill-container {
    height: 80px;
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    padding: 10px;
    border: 1px solid black;
    box-shadow: 1px 1px 1px 1px gray;
}

.fab {
    font-size: 50px;
    height: 50px;
    margin-bottom: 5px;
    color: #993399;
}

.skill-name {
    font-size: 30px;
}

.footnote {
    bottom: 15px;
    left: 30%;
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    padding-left: 21%;
}