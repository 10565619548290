@import url('https://fonts.googleapis.com/css?family=Montserrat|Open+Sans|Pacifico&display=swap');

.App {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  font-family: 'Montserrat', 'Open Sans', sans-serif;
}

.header-info {
  display: flex;
  align-items: flex-end;
  font-family: 'Pacifico', cursive;
  font-size: 30px;
  color: black;
  height: 85%;
}

.headshot-container {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  border: 1px solid black;
}

.header-image {
  height: 160px;
  width: 100px;
}

.header-nav {
  display: flex;
  font-family: 'Open Sans', sans-serif;
  color: white;
  font-size: 25px;
}

.nav-link {
  margin: 0px 10px 0px;
  position: relative;
}

a {
  text-decoration: none;
  border: none;
  color: white;
}

a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

a:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

a.selected:before {
  visibility: visible !important;
  transform: scaleX(1) !important;
}

header {
  width: 100%;
  height: 12%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0px 40px 0px;
  background-color: #8B9568;
  box-shadow: 0px 3px 10px 0px black;
}

section {
  height: 100%;
  width: 100%;
  padding-top: 6%;
  box-sizing: border-box;
}

@media screen and (max-height: 900px) {
  .header-info {
    position: relative;
    width: 325px;
  }

  .header-info span {
    position: absolute;
    right: 0px;
  }

  .headshot-container {
    position: absolute;
    bottom: -55px;
  }
}