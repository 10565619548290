.home-container {
    width: 100%;
    height: 100%;
}

.home-welcome {
    background-image: url('./roadbackground.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.welcome-h1 {
    font-family: 'Open Sans';
    font-size: 80px;
    font-weight: bold;
}

.welcome-span {
    font-family: 'Montserrat', 'Open Sans', sans-serif;
    font-size: 22px;
}

.home-section {
    height: 100vh;
    width: 100vw;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
}

.home-sub-section {
    height: 50%;
    width: 100%;
    background-color: white;
    font-family: 'Montserrat', 'Open Sans', sans-serif;
    position: absolute;
    top: 50%;
    left: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    box-sizing: border-box;
    padding: 0px 80px 0px;
    line-height: 40px;
}

.home-resume {
    text-decoration: none;
    color: #996699;
}

.home-resume:hover {
    text-decoration: none;
    color: #ccaacc;
}

.home-resume:active {
    text-decoration: none;
    color: #996699;
}

.home-resume:visited {
    text-decoration: none;
    color: #996699;
}