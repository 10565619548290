@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans|Pacifico&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home-container {
    width: 100%;
    height: 100%;
}

.home-welcome {
    background-image: url(/static/media/roadbackground.933c633a.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.welcome-h1 {
    font-family: 'Open Sans';
    font-size: 80px;
    font-weight: bold;
}

.welcome-span {
    font-family: 'Montserrat', 'Open Sans', sans-serif;
    font-size: 22px;
}

.home-section {
    height: 100vh;
    width: 100vw;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
}

.home-sub-section {
    height: 50%;
    width: 100%;
    background-color: white;
    font-family: 'Montserrat', 'Open Sans', sans-serif;
    position: absolute;
    top: 50%;
    left: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    box-sizing: border-box;
    padding: 0px 80px 0px;
    line-height: 40px;
}

.home-resume {
    text-decoration: none;
    color: #996699;
}

.home-resume:hover {
    text-decoration: none;
    color: #ccaacc;
}

.home-resume:active {
    text-decoration: none;
    color: #996699;
}

.home-resume:visited {
    text-decoration: none;
    color: #996699;
}
.about-container {
    height: 80%;
    width: 100%;
    box-sizing: border-box;
    padding-top: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
}

.about-container div {
    margin: 0px 10px 0px;
}

.about-content-container {
    margin-top: 50px;
    height: calc(76% - 100px);
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    margin-bottom: 50px;
    margin-right: 7%;
}

.about-content-h1 {
    font-size: 30px;
    font-family: 'Open Sans', 'Montserrat', sans-serif;
    font-weight: bold;
    margin-bottom: 20px;
}

.about-content {
    width: 95%;
    margin-bottom: 50px;
    font-size: 20px;
    line-height: 25px;
}

.about-img-container {
    margin-top: 100px;
    height: 475px;
    width: 350px;
    border-radius: 5px;
    overflow: hidden;
}

.about-img {
    height: 100%;
    width: 100%;
}
.skills-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /* margin-top: 8%; */
}

.skills-list {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    align-items: center;
    justify-content: space-evenly;
    width: 60%;
    box-sizing: border-box
}

.skill-container {
    height: 80px;
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    padding: 10px;
    border: 1px solid black;
    box-shadow: 1px 1px 1px 1px gray;
}

.fab {
    font-size: 50px;
    height: 50px;
    margin-bottom: 5px;
    color: #993399;
}

.skill-name {
    font-size: 30px;
}

.footnote {
    bottom: 15px;
    left: 30%;
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    padding-left: 21%;
}
.contact-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.contact-h1 {
    font-family: 'Open Sans', 'Montserrat', sans-serf;
    font-size: 25px;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
}

.contact-link-badges-container {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.contact-link-badges {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.contact-email-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
}

.contact-email-form textarea {
    width: 60%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    resize: none;
    border: 1px solid gray;
    border-radius: 5px;
    font-family: 'Montserrat', 'Open Sans', sans-serif;
}

.contact-email-form textarea:focus {
    border: 1px solid gray;
}

.contact-email-form-inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 60%;
}

.contact-email-form-inputs input {
    padding: 12px 20px;
    box-sizing: border-box;
    width: 200px;
    border: 1px solid gray;
    border-radius: 5px;
    font-family: 'Montserrat', 'Open Sans', sans-serif;
}

.contact-email-form-inputs input:focus {
    border: 1px solid gray;
}

.contact-email-form button {
    border: 2px solid #993399;
    background-color: #996699;
    color: white;
    font-family: 'Open Sans', 'Montserrat', sans-serif;
    font-size: 16px;
    margin-top: 10px;
    border-radius: 5px;
}

.contact-resume-container {
    color: black;
    margin-bottom: 20px;
}

.contact-resume {
    color: #8B9568;
}

.contact-me {
    margin-bottom: 20px;
}

.contact-me h1 {
    font-size: 25px;
    font-family: 'Open Sans', 'Montserrat', sans-serf;
}

/* .Toastify__toast-container {
    position: fixed !important;
    bottom: 20px !important;
    right: 20px !important;
}

.Toastify__toast--success {
    z-index: 99;
    background-color: #9999ff;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
}

.Toastify__toast--error {
    z-index: 99;
    background-color: #ff9999;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
} */
.projects-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.project {
    height: 550px;
    width: 400px;
    box-shadow: 2px 2px 2px 2px gray;
    border: 1px solid black;
    background-size: 400px 300px;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    /* overflow-y: scroll; */
    box-sizing: border-box;
    margin: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.project-details {
    height: 250px;
    width: 400px;
    background-color: white;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
    border-right: 1px solid black;
}

.project-details p {
    line-height: 25px;
}

.projects-link {
    color: #993399;
}

.bold {
    font-weight: bold;
}

#wandsattheready {
    background-image: url(/static/media/wandsatthereadyportfolio.ce386b10.png)
}

#digital-couch {
    background-image: url(/static/media/digitalcouchportfolio.044b56c0.png)
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.App {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  font-family: 'Montserrat', 'Open Sans', sans-serif;
}

.header-info {
  display: flex;
  align-items: flex-end;
  font-family: 'Pacifico', cursive;
  font-size: 30px;
  color: black;
  height: 85%;
}

.headshot-container {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  border: 1px solid black;
}

.header-image {
  height: 160px;
  width: 100px;
}

.header-nav {
  display: flex;
  font-family: 'Open Sans', sans-serif;
  color: white;
  font-size: 25px;
}

.nav-link {
  margin: 0px 10px 0px;
  position: relative;
}

a {
  text-decoration: none;
  border: none;
  color: white;
}

a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  visibility: hidden;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

a.selected:before {
  visibility: visible !important;
  -webkit-transform: scaleX(1) !important;
          transform: scaleX(1) !important;
}

header {
  width: 100%;
  height: 12%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0px 40px 0px;
  background-color: #8B9568;
  box-shadow: 0px 3px 10px 0px black;
}

section {
  height: 100%;
  width: 100%;
  padding-top: 6%;
  box-sizing: border-box;
}

@media screen and (max-height: 900px) {
  .header-info {
    position: relative;
    width: 325px;
  }

  .header-info span {
    position: absolute;
    right: 0px;
  }

  .headshot-container {
    position: absolute;
    bottom: -55px;
  }
}
